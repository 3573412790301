import { WPPostCategoryLabel } from "@models/posts"

export const formatPostDate = (dateISO: string): string => {
  const date = new Date(dateISO)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")

  return `${year}.${month}.${day}`
}

export const formatPostCategory = (
  categories: string[] | null | undefined,
  postCategoryLabel: WPPostCategoryLabel
): string => {
  const { default: defaultLabel, event, news } = postCategoryLabel
  if (!categories) return defaultLabel

  if (categories.length > 1)
    console.warn(`Got multiple categories, will only use the first one. ${categories}`)

  if (categories[0] === "event") return event
  if (categories[0] === "nyheter") return news

  return defaultLabel
}
